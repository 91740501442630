import * as PIXI from 'pixi.js'
window['PIXI'] = PIXI
require('pixi.js');
require('pixi-layers')
require('pixi-spine')
require('pixi-tween')

const VERSION = '0.0.5a'
const UPDATED = '2020/07/20'
const ELEMENT_ID = 'lievreChat'

let LievreChat = class {
  constructor() {
    this.sayHello()
    this.stageElement = document.getElementById(ELEMENT_ID);
    this.app = new PIXI.Application({
      view: this.stageElement,
      width: 800,
      height: 600,
      backgroundColor: 0x000000,
      resolution: 2,
      forceCanvas: false,
      autoResize: true,
      antialias: true,
    })
    this.element = []
    this.tween = []
    this.camera = {
      'move': 10
    }
    this.waitLoadElement = []
    this.fontFamily = "Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif"

    this.app.ticker.maxFPS = 30
    this.app.ticker.minFPS = 24
    this.app.ticker.add(function (delta) {
      PIXI.tweenManager.update()
    })

    this.myUserId
    this.mapEditor = true
    this.hitSquareCount = 0
    this.hitSquareRanges = []
    this.hitSquares = []
    this.hitSquareSize = 40
    this.hitSquareCount = 0
    this.nameAdjustmentX = 40

    this.balloonFontSize = 72
    this.balloonInsideMargin = 72
    this.balloonAdjustmentY = 0
    this.ballooonRadius = 64

    this.isStageDrag = false
    this.isStageMoveFocus = false

    this.syncCash = []
    this.app.stage = new PIXI.display.Stage()
    this.container = new PIXI.Container()
    this.avatarLayer = new PIXI.display.Layer()
    this.hitAreaLayer = new PIXI.display.Layer()
    this.avatarLayer.group.enableSort = true
    this.hitAreaLayer.group.enableSort = true
    document.body.appendChild(this.app.view)
  }

  sayHello() {
    var args = [
      ("\n %c  LievreChat for usagoya version " + VERSION + " updated " + UPDATED + "  \n\n"),
      'color: #cfe6a1; background: #030307; padding:5px 0;'
    ]
    window.console.log.apply(console, args)
  }

  start(userId, areaAvatarList) {
    return new Promise((resolve, reject) => {
      this.app.stage.interactive = true
      this.myUserId = userId
      this.background = new PIXI.Sprite.from(areaAvatarList.background)
      this.background.interactive = true

      this.app.stage.addChild(this.background)
      this.app.stage.addChild(this.container)
      this.container.addChild(this.hitAreaLayer)
      this.container.addChild(this.avatarLayer)
      this.loadAvatarList(areaAvatarList.element).then((loader) => {
        loader.onProgress.add((loader, resources) => { })
        loader.onError.add(() => {
          reject({
            code: 'error'
          })
        })
        loader.onComplete.add(() => {
          this.addDragCameraPositionEvent()
          this.app.start()
          if (areaAvatarList.hitSquare) {
            for (let hitSquare of areaAvatarList.hitSquare) {
              this.createHitSquare(hitSquare);
            }
            //this.getHitSquareJson()
          }
          resolve({
            code: 'success'
          })
        })
      })
    })
  }

  loadAvatarList(param) {
    return new Promise((resolve) => {
      let loader = new PIXI.Loader()
      let element
      for (element of param) {
        if (this.isExist(element.prefix, element.id)) {
          continue
        }
        loader.add(element.prefix + element.id, element.json)
      }
      loader.load((loader, resources) => {
        for (element of param) {
          if (this.isExist(element.prefix, element.id)) {
            continue
          }
          this.setAvatar(resources[element.prefix + element.id], element)
        }
      })
      resolve(loader)
      loader = null
      element = null
    })
  }

  setAvatar(resource, element) {
    this.element[element.prefix + element.id] = {}
    this.element[element.prefix + element.id].spine = {}
    this.element[element.prefix + element.id].spine = new PIXI.spine.Spine(resource.spineData)
    this.element[element.prefix + element.id].spine.parentLayer = this.avatarLayer
    this.element[element.prefix + element.id].spine.x = element.x
    this.element[element.prefix + element.id].spine.y = element.y
    this.element[element.prefix + element.id].spine.r = element.r
    this.element[element.prefix + element.id].spine.distanceX = 0;
    this.element[element.prefix + element.id].spine.distanceY = 0;
    this.element[element.prefix + element.id].spine.moveCount = 0;
    this.element[element.prefix + element.id].spine.moveHistory = { x: 0, y: 0 };
    this.element[element.prefix + element.id].spine.zOrder = element.y
    this.element[element.prefix + element.id].spine.scale.x = this.element[element.prefix + element.id].spine.scale.y = element.scale
    this.element[element.prefix + element.id].spine.interactive = true
    this.element[element.prefix + element.id].spine.on('mouseup', (e) => {
    })

    // test
    // let square = new PIXI.Graphics()
    // square.beginFill(0x000000)
    // square.drawRect(0, 0, this.hitSquareSize, this.hitSquareSize)
    // square.endFill()
    // console.log(this.element[element.prefix + element.id].spine.spineData.skins[0].attachments);

    // ballloon
    this.element[element.prefix + element.id].spine.balloon = new PIXI.Container()
    this.element[element.prefix + element.id].spine.balloon.y = this.element[
      element.prefix + element.id
    ].spine.spineData.height * -1 + this.balloonAdjustmentY;

    // user name
    this.element[element.prefix + element.id].spine.name = new PIXI.Text(element.name, new PIXI.TextStyle({
      align: "center",
      fontFamily: this.fontFamily,
      fontSize: 60,
      fontWeight: "bold",
      dropShadow: true,
      dropShadowAlpha: 0.2,
      dropShadowAngle: 1.5,
      dropShadowDistance: 2,
      fill: 0xffffff,
    }))
    this.element[element.prefix + element.id].spine.name.anchor.set(0.5);
    this.element[element.prefix + element.id].spine.name.x = this.element[
      element.prefix + element.id
    ].spine.width / 2 - this.nameAdjustmentX;
    this.element[element.prefix + element.id].spine.name.y = 60

    element.r == 1 ? this.forceUpdateReverse(element.prefix, element) : null
    if (element.prefix == "user" && this.myUserId == element.id) {
      this.updateCameraPosition({
        x: element.x,
        y: element.y
      })
    }
    this.avatarLayer.group.enableSort = true
    this.element[element.prefix + element.id].spine.addChild(
      this.element[element.prefix + element.id].spine.name
    )
    this.element[element.prefix + element.id].spine.addChild(
      this.element[element.prefix + element.id].spine.balloon
    )
    this.container.addChild(this.element[element.prefix + element.id].spine)
    this.element[element.prefix + element.id].spine.state.setAnimation(0, 'idle', true)
  }

  getMyStatus() {
    if (this.element['user' + this.myUserId]) {
      return {
        id: this.myUserId,
        x: this.element['user' + this.myUserId].spine.x,
        y: this.element['user' + this.myUserId].spine.y,
        r: this.element['user' + this.myUserId].spine.r
      }
    }
    return {}
  }

  updateMyStatus(param) {
    if (this.element['user' + this.myUserId]) {
      this.element['user' + this.myUserId].spine.x = param.x
      this.element['user' + this.myUserId].spine.y = param.y
      this.element['user' + this.myUserId].spine.r = param.r
    }
    return {}
  }

  isExist(prefix, userId) {
    if (this.element[prefix + userId]) {
      return true;
    }
    return false;
  }

  remove(prefix, userId) {
    this.removeTween(prefix, userId)
    if (this.isExist(prefix, userId)) {
      this.container.removeChild(this.element[prefix + userId].spine)
      this.element[prefix + userId] = null
    }
  }

  removeTween(prefix, userId) {
    if (this.tween[prefix + userId]) {
      this.tween[prefix + userId].stop()
      this.tween[prefix + userId] = null
    }
  }

  walk(callback) {
    try {
      this.app.stage.on('pointerup', (e) => {
        if (this.isStageMoveFocus == true) {
          this.isStageMoveFocus = false
          return
        }
        this.move('user', {
          id: this.myUserId,
          speed: 2,
          sx: Math.round(this.element['user' + this.myUserId].spine.x),
          sy: Math.round(this.element['user' + this.myUserId].spine.y),
          x: Math.round(e.data.getLocalPosition(e.currentTarget).x),
          y: Math.round(e.data.getLocalPosition(e.currentTarget).y)
        }, (result) => {
          callback(result)
        })
      })
    } catch (e) {
      throw new Error('element error')
    }
  }

  autoWalk(param, callback) {
    try {
        this.move('user', {
          id: this.myUserId,
          speed: 2,
          sx: Math.round(this.element['user' + this.myUserId].spine.x),
          sy: Math.round(this.element['user' + this.myUserId].spine.y),
          x: Math.round(param.x),
          y: Math.round(param.y)
        }, (result) => {
          callback(result)
        })
    } catch (e) {
      throw new Error('element error')
    }
  }

  move(prefix, param, callback) {
    try {
      this.element[prefix + param.id].spine.moveCount = 1;
      this.element[prefix + param.id].spine.moveHistory = { x: param.sx, y: param.sy };
      this.removeTween(prefix, param)

      this.tween[prefix + param.id] = PIXI.tweenManager.createTween(this.element[
        prefix + param.id
      ].spine)
      this.tween[prefix + param.id].time = this.getDistance(this.element[prefix + param.id].spine, param.x, param.y) / (param.speed * 0.1)
      this.tween[prefix + param.id].repeat = 0
      this.tween[prefix + param.id].from({
        x: this.element[prefix + param.id].spine.x,
        y: this.element[prefix + param.id].spine.y
      }).to({
        x: param.x,
        y: param.y
      })
      this.tween[prefix + param.id].start()

      this.tween[prefix + param.id].on('start', () => {
        try {
          this.updateReverse(prefix, param)
          this.element[prefix + param.id].spine.state.setAnimation(0, 'run', true)
          callback({
            id: param.id,
            speed: param.speed,
            sx: param.sx,
            sy: param.sy,
            x: param.x,
            y: param.y,
            r: this.element[prefix + param.id].spine.r,
          })
        } catch (e) {
          callback('error')
        }
      })
      this.tween[prefix + param.id].on('update', (e) => {
        try {
          if (this.hitTest(prefix, param)) {
            this.tween[prefix + param.id].stop()
            callback({
              id: param.id,
              speed: param.speed,
              sx: Math.round(this.element[prefix + param.id].spine.x),
              sy: Math.round(this.element[prefix + param.id].spine.y),
              x: Math.round(this.element[prefix + param.id].spine.moveHistory.x),
              y: Math.round(this.element[prefix + param.id].spine.moveHistory.y),
              r: this.element[prefix + param.id].spine.r,
            })
            this.element[prefix + param.id].spine.x = this.element[prefix + param.id].spine.moveHistory.x
            this.element[prefix + param.id].spine.y = this.element[prefix + param.id].spine.moveHistory.y
          } else {
            if (this.element[prefix + param.id].spine.moveCount % 2 == 0) {
              this.element[prefix + param.id].spine.moveHistory = {
                x: this.element[prefix + param.id].spine.x,
                y: this.element[prefix + param.id].spine.y
              };
            }
            this.element[prefix + param.id].spine.zOrder = Math.round(this.element[prefix + param.id].spine.y)
            this.element[prefix + param.id].spine.moveCount++
            if (this.myUserId == param.id) {
              this.updateCameraPosition({
                x: Math.round(this.element[prefix + param.id].spine.x),
                y: Math.round(this.element[prefix + param.id].spine.y)
              })
            }
          }
        } catch (e) {
          callback('error')
        }
      })
      this.tween[prefix + param.id].on('stop', () => {
        try {
          this.element[prefix + param.id].spine.state.setAnimation(0, 'idle', true)
          if (this.myUserId == param.id) {
            callback({
              id: param.id,
              speed: param.speed,
              sx: Math.round(param.sx),
              sy: Math.round(param.sy),
              x: Math.round(this.element[prefix + param.id].spine.x),
              y: Math.round(this.element[prefix + param.id].spine.y),
              r: this.element[prefix + param.id].spine.r,
            })
          }
        } catch (e) {
          callback('error')
        }
      })
      this.tween[prefix + param.id].on('end', () => {
        try {
          this.removeTween(prefix, param)
        } catch (e) {
          callback('error')
        }
      })
    } catch (e) {
      callback('error')
    }
  }

  removeTween(prefix, param) {
    if (this.tween[prefix + param.id]) {
      this.tween[prefix + param.id].stop()
      PIXI.tweenManager.removeTween(this.element[prefix + param.id].spine)
      this.tween[prefix + param.id] = null
      this.element[prefix + param.id].spine.state.setAnimation(0, 'idle', true)
    }
  }

  syncCash(param) {
    this.syncCash[param.id].push(param)
  }

  sync(users) {
    let userId
    let elementKey

    for (userId in users) {
      if (!users[userId].metas[0] || this.myUserId == userId) {
        continue
      }
      if (this.isExist('user', users[userId].metas[0].id)) {
        if (!this.tween['user' + userId]) {
          this.element['user' + userId].x = users[userId].metas[0].x
          this.element['user' + userId].y = users[userId].metas[0].y
          this.element['user' + userId].r = users[userId].metas[0].r
        }
      } else {
        this.loadAvatarList([
          {
            id: userId,
            prefix: 'user',
            name: users[userId].metas[0].name,
            x: users[userId].metas[0].x,
            y: users[userId].metas[0].y,
            r: users[userId].metas[0].r,
            scale: users[userId].metas[0].scale,
            json: users[userId].metas[0].element
          }
        ])
      }
    }
    for (elementKey in this.element) {
      if (elementKey.indexOf('user') != -1) {
        if (users[elementKey.replace('user', '')]) {
          continue
        }
        this.remove('user', elementKey.replace('user', ''))
      }
    }
    users = null
    userId = null
    elementKey = null
  }

  getDistance(element, moveX, moveY) {
    element.distanceX = Math.round(element.x - moveX);
    element.distanceY = Math.round(element.y - moveY);
    element.distanceX < 0 ? element.distanceX = element.distanceX * -1 : null;
    element.distanceY < 0 ? element.distanceY = element.distanceY * -1 : null;
    return Math.round(Math.sqrt(
      (element.distanceX * element.distanceX) + (element.distanceY * element.distanceY)
    ));
  }

  forceUpdateReverse(prefix, param) {
    this.element[prefix + param.id].spine.scale.x *= -1
    this.element[prefix + param.id].spine.name.scale.x *= -1
    this.element[prefix + param.id].spine.balloon.scale.x *= -1
  }

  updateReverse(prefix, param) {
    if (param.sx >= param.x && this.element[prefix + param.id].spine.scale.x > 0) {
      this.element[prefix + param.id].spine.r = 1
      this.forceUpdateReverse(prefix, param)
    } else if (param.sx <= param.x && this.element[prefix + param.id].spine.scale.x < 0) {
      this.element[prefix + param.id].spine.r = 0
      this.forceUpdateReverse(prefix, param)
    }
  }

  createHitSquare(params) {
    let square = new PIXI.Graphics()
    let hitAreaContainer = new PIXI.Container()
    let alpha = this.mapEditor ? 0.2 : 0
    square.beginFill(0x000000, alpha)
    square.drawRect(0, 0, this.hitSquareSize, this.hitSquareSize)
    square.endFill()
    square.interactive = true
    square.buttonMode = true

    this.hitSquareCount++
    hitAreaContainer.addChild(square)
    hitAreaContainer.hitArea = new PIXI.Rectangle(params.x, params.y, this.hitSquareSize, this.hitSquareSize)
    hitAreaContainer.id = this.hitSquareCount
    hitAreaContainer.type = params.type
    hitAreaContainer.x = params.x
    hitAreaContainer.sx = params.x
    hitAreaContainer.y = params.y

    if (this.mapEditor) {
      this.addDragHitSquareEvent(square)
    }
    square.parentLayer = this.hitAreaLayer
    this.addRange(hitAreaContainer, params)
    this.hitSquares[this.hitSquareCount] = hitAreaContainer
    this.container.addChild(this.hitSquares[this.hitSquareCount])
  }

  addRange(hitAreaContainer, params) {
    let rangeX
    let rangeY
    let position = this.getHitRange(params.x, params.y)
    for (rangeX = (position.x - 1); rangeX <= (position.x + 1); rangeX++) {
      if (!this.hitSquareRanges[rangeX]) {
        this.hitSquareRanges[rangeX] = []
      }
      for (rangeY = (position.y - 1); rangeY <= (position.y + 1); rangeY++) {
        if (!this.hitSquareRanges[rangeX][rangeY]) {
          this.hitSquareRanges[rangeX][rangeY] = []
        }
        this.hitSquareRanges[rangeX][rangeY].push(hitAreaContainer.id)
      }
    }
  }

  addDragHitSquareEvent(square) {
    let isDrag = false
    square.on('pointerdown', (e) => {
      isDrag = true
      e.stopPropagation()
    })
    square.on('pointerup', (e) => {
      isDrag = false
      console.log(e.currentTarget.parent.hitArea)
      e.currentTarget.parent.hitArea = new PIXI.Rectangle(
        e.currentTarget.parent.x,
        e.currentTarget.parent.y,
        this.hitSquareSize,
        this.hitSquareSize
      )
      this.addRange(e.currentTarget.parent, { x: e.currentTarget.parent.x, y: e.currentTarget.parent.y })
      console.log(e.currentTarget.parent.hitArea)
      e.stopPropagation()
    })
    square.on('pointermove', (e) => {
      if (isDrag) {
        e.currentTarget.parent.x = Math.round(e.data.getLocalPosition(this.app.stage).x - e.currentTarget.width / 2)
        e.currentTarget.parent.y = Math.round(e.data.getLocalPosition(this.app.stage).y - e.currentTarget.height / 2)
        e.stopPropagation()
      }
    })
  }

  getHitSquareJson() {
    for (let hitSquare of this.hitSquares) {
      console.log(hitSquare)
    }
  }

  hitTest(prefix, param) {
    try {
      let key
      let position = {};
      position = this.getHitRange(this.element[prefix + param.id].spine.x, this.element[prefix + param.id].spine.y);
      for (key in this.hitSquareRanges[position.x][position.y]) {
        if (this.hitSquares[this.hitSquareRanges[position.x][position.y][key]].hitArea.contains(
          this.element[prefix + param.id].spine.x,
          this.element[prefix + param.id].spine.y
        )) {
          console.log('hitTest', this.hitSquareRanges)
          key = null
          position = null;
          return true;
        }
      }
      key = null
      position = null;
    } catch (e) {
    }
    return false;
  }

  getHitRange(x, y) {
    return {
      x: Math.floor(x / this.hitSquareSize),
      y: Math.floor(y / this.hitSquareSize)
    }
  }

  comment(prefix, param) {
    let container = new PIXI.Container()
    let childContainer;
    let numChildren;
    let frameGraphics = new PIXI.Graphics()
    let insideGraphics = new PIXI.Graphics()
    let tailFrameGraphics = new PIXI.Graphics()
    let tailInsideGraphics = new PIXI.Graphics()
    let comment = new PIXI.Text(param.comment ?? "　", new PIXI.TextStyle({
      align: "left",
      fontFamily: this.fontFamily,
      fontSize: this.balloonFontSize,
      fontWeight: "bold",
      dropShadow: true,
      dropShadowAlpha: 0.2,
      dropShadowAngle: 1.5,
      dropShadowDistance: 2,
      fill: 0x333333,
      wordWrap: true,
      wordWrapWidth: this.balloonFontSize * 8,
      breakWords: true
    }))
    let deleteBalloon = () => {
      // garbage collection
      clearTimeout(timerId);
      if (this.element[prefix + param.id]) {
        this.element[prefix + param.id].spine.balloon.removeChild(container)
      }
      insideGraphics.removeChild(comment)
      container = null
      frameGraphics = null
      insideGraphics = null
      tailFrameGraphics = null
      tailInsideGraphics = null
      comment = null
      timerId = null
      childContainer = null
      numChildren = null
      deleteBalloon = null
    }
    let timerId = setTimeout(deleteBalloon, param.timer ?? 5000);

    // baloon frame
    frameGraphics.beginFill(param.frameColor ?? 0x333333);
    frameGraphics.drawRoundedRect(
      0,
      0,
      comment.width + this.balloonInsideMargin + 4,
      comment.height + this.balloonInsideMargin + 4,
      this.ballooonRadius
    );
    frameGraphics.endFill();

    // baloon inside
    insideGraphics.beginFill(param.insideColor ?? 0xffffff);
    insideGraphics.drawRoundedRect(
      2,
      2,
      comment.width + this.balloonInsideMargin - 1,
      comment.height + this.balloonInsideMargin - 1,
      this.ballooonRadius
    );
    insideGraphics.endFill();

    // baloon tail frame
    tailFrameGraphics.beginFill(param.frameColor ?? 0x333333);
    tailFrameGraphics.moveTo(frameGraphics.width / 2 + 34, frameGraphics.height);
    tailFrameGraphics.lineTo(frameGraphics.width / 2 - 34, frameGraphics.height);
    tailFrameGraphics.lineTo(frameGraphics.width / 2, frameGraphics.height + 36);
    tailFrameGraphics.endFill();

    // baloon inside frame
    tailInsideGraphics.beginFill(param.frameColor ?? 0xffffff);
    tailInsideGraphics.moveTo(frameGraphics.width / 2 + 32, frameGraphics.height - 4);
    tailInsideGraphics.lineTo(frameGraphics.width / 2 - 32, frameGraphics.height - 4);
    tailInsideGraphics.lineTo(frameGraphics.width / 2, frameGraphics.height + 32);
    tailInsideGraphics.endFill();

    // comment position
    comment.x = this.balloonInsideMargin / 2;
    comment.y = this.balloonInsideMargin / 2;

    insideGraphics.addChild(comment)
    container.addChild(frameGraphics)
    container.addChild(tailFrameGraphics)
    container.addChild(tailInsideGraphics)
    container.addChild(insideGraphics)
    container.x = container.width / 2 * -1
    container.y = container.height * -1

    for (numChildren = 0; numChildren < (this.element[
      prefix + param.id
    ].spine.balloon.children.length); numChildren++) {
      childContainer = this.element[prefix + param.id].spine.balloon.getChildAt(numChildren);
      childContainer.y = (childContainer.y - childContainer.height);
    }
    this.element[prefix + param.id].spine.balloon.addChild(container)
  }

  updateCameraPosition(param) {
    param.x = Math.round((this.app.view.clientWidth / 2) - param.x)
    param.y = Math.round((this.app.view.clientHeight / 2) - param.y + 100)
    if (0 <= param.x) {
      param.x = 0
    } else if (param.x <= (this.app.view.clientWidth - this.background.width)) {
      param.x = this.app.view.clientWidth - this.background.width
    }
    if (0 <= param.y) {
      param.y = 0
    } else if (param.y <= (this.app.view.clientHeight - this.background.height)) {
      param.y = this.app.view.clientHeight - this.background.height
    }
    this.app.stage.position.x = param.x
    this.app.stage.position.y = param.y
  }

  addDragCameraPositionEvent() {
    let coordinate = {
      baseX: 0,
      baseY: 0,
      pointerDownX: 0,
      pointerDownY: 0,
      pointerMoveX: 0,
      pointerMoveY: 0
    }
    let timerId
    let startDrag = () => {
      coordinate['baseX'] = this.element['user' + this.myUserId].spine.x
      coordinate['baseY'] = this.element['user' + this.myUserId].spine.y
      this.isStageDrag = true
      clearTimeout(timerId);
    }

    this.app.stage.on('pointerdown', (e) => {
      timerId = setTimeout(startDrag, 500)
    })
    this.app.stage.on('pointerup', (e) => {
      this.isStageDrag = false
      clearTimeout(timerId)
    })
    this.app.stage.on('pointerout', (e) => {
      this.isStageDrag = false
      clearTimeout(timerId)
    })
    this.app.stage.on('pointermove', (e) => {
      if (this.isStageDrag) {
        this.isStageMoveFocus = true
        coordinate['pointerMoveX'] = coordinate['pointerDownX'] - this.app.renderer.plugins.interaction.mouse.global.x
        coordinate['pointerMoveY'] = coordinate['pointerDownY'] - this.app.renderer.plugins.interaction.mouse.global.y
        this.updateCameraPosition({
          x: Math.round(coordinate['baseX'] - coordinate['pointerMoveX']),
          y: Math.round(coordinate['baseY'] - coordinate['pointerMoveY'])
        })
      } else {
        coordinate['pointerDownX'] = this.app.renderer.plugins.interaction.mouse.global.x
        coordinate['pointerDownY'] = this.app.renderer.plugins.interaction.mouse.global.y
      }
      e.stopPropagation()
    })
  }
}

export default new LievreChat()
