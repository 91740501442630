import "phoenix_html"

//import socket from "./socket"
import {
  Socket
} from 'phoenix'
import chat from "./lievre/chat"

if (!localStorage.avatarId) {
  localStorage.avatarId = Math.floor(Math.random() * (1000000 + 1 - 1)) + 1
}
const userId = localStorage.avatarId;
const areaId = localStorage.roomId;

// const soketUrl = "wss://chat1.usagoya.com/socket"
const soketUrl = "wss://127.0.0.1:4000/socket"
let socket = new Socket(soketUrl, {
  params: {
    token: 'test',
    user_id: userId,
    name: userId,
  }
})
socket.connect()
let channel = socket.channel('area:' + (areaId ?? 1), {})

socket.onError(() => {
  alert('connection error')
  socket = null
  document.location.reload()
})
socket.onClose(() => {
  alert('connection close')
  socket = null
  document.location.reload()
})

socket.onOpen(() => {
  chat.start(userId, {
    "background": "/images/background.png",
    "element": [
      {
        id: userId,
        name: userId,
        prefix: 'user',
        x: 1200,
        y: 600,
        r: 1,
        scale: 0.2,
        json: '/images/avatar/2/spineboy-pro.json'
      }
    ],
    "hitSquare": [
      {
        type: 'hit',
        x: 620,
        y: 500
      },
      {
        type: 'hit',
        x: 600,
        y: 480
      },
      {
        type: 'hit',
        x: 500,
        y: 480
      },
      {
        type: 'hit',
        x: 700,
        y: 550
      },
      {
        type: 'hit',
        x: 720,
        y: 400
      },
    ]
  }).then((result) => {
    //console.log("start", result)

    // setTimeout(
    //   function () {
    //     setInterval(() => {
    //       let param = {
    //         x: Math.floor(Math.random() * Math.floor(1000)),
    //         y: Math.floor(Math.random() * Math.floor(1000))
    //       }
    //       chat.autoWalk(param, (result) => {
    //         channel.push('walk', result)
    //       })
    //     }, 1000);
    //   },
    //   '10000'
    // );

    document.getElementById("comment").addEventListener("keydown", (e) => {
      e.stopPropagation()
      if (document.getElementById("comment").value != "") {
        console.log(e.keyCode)
        if (e.keyCode == 13) {
          channel.push('comment', {
            id: userId,
            comment: document.getElementById("comment").value
          })
          console.log(document.getElementById("comment").value)
          document.getElementById("comment").value = ""
        }
      }
    });
    document.getElementById("comment-button").addEventListener("click", (e) => {
      if (document.getElementById("comment").value != "") {
        channel.push('comment', {
          id: userId,
          comment: document.getElementById("comment").value
        })
        console.log(document.getElementById("comment").value)
        document.getElementById("comment").value = ""
      }
    });

    chat.walk((result) => {
      channel.push('walk', result)
    })
    channel.join().receive("ok", result => {
      console.log('ok', result)
    })
    channel.on('walk', function (param) {
      if (userId != param.id) {
        chat.move('user', param, (result) => {
          if (result == 'error') {
          }
        })
      }
    })
    channel.on('comment', function (param) {
      console.log('comment', param)
      chat.comment('user', param)
    })
    channel.on('sync', function (users) {
      chat.sync(users)
    })
    channel.on('connect', function (params) {
      channel.push('sync')
    })
    channel.on('login', function (users) {
      console.log('login', users)
      chat.sync(users)
    })
    channel.on('logout', function (user) {
      channel.push('sync')
    })
  }).catch((error) => {
    console.error(error)
  })
})
